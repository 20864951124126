import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import getDocDefinition from "./docDefinition";
import moment from 'moment'
pdfMake.vfs = pdfFonts.pdfMake.vfs;



function printDoc(printParams, gridApi, columnApi, customerName) {
  console.log("Exporting to PDF...");
  const docDefinition = getDocDefinition(printParams, gridApi, columnApi, customerName);
  pdfMake.createPdf(docDefinition).download("Fabric_Report_"+moment().utcOffset('+05:30').format('YYYY-MM-DD hh:mm:ss a')+".pdf");
}

export default printDoc;
