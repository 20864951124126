export const routes = {
    LOGIN:'/login',
    HOME:'/',
    INVENTORY:'/inventory',
    HOMECONTENT:'/home',
    FORGETPASSWORD:'/forgotPassword',
    RESETLINK:'/resetPassword',
    ADMIN: '/admin',
    PROFILE:'/profile',
    ADDAPPLICATION: '/Addapplications'
}